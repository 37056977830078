body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  
}

*:focus {
  box-shadow: none !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.g-header {
  grid-area: header;
  position: sticky;
  top:0;
  left:0;
  z-index: 100;
}

.g-main {
  grid-area: main;
  overflow: auto;
  background-color: var(--surface-200);
  align-items: stretch;
}

.g-footer {
  grid-area: footer;
}

.home{
  min-height: 100vh;
}

.container {
  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  grid-template-areas: 
    "header"
    "main"
    "footer";
}

body a {
  color: inherit;
  text-decoration: none;
}

.datatable-templating .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.st0{fill:#020203;}
	.st1{fill:none;}
	.st2{fill:#FFFFFF;}
	.st3{fill:none;stroke:#020203;stroke-miterlimit:10;}
/*.p-password input {
  width: "235px";
  }*/
  .blink {
    animation: blinker 1.2s linear infinite;
    color: #1c87c9;
   }
  @keyframes blinker {  
    50% { opacity: 0; }
   }
   .blink-one {
     animation: blinker-one 1s linear infinite;
   }
   @keyframes blinker-one {  
     0% { opacity: 0; }
   }
   .blink-two {
     animation: blinker-two 1.4s linear infinite;
   }
   @keyframes blinker-two {  
     100% { opacity: 0; }
   }